export default [

 {//default fallback...
    url:/.+/,
    base:"only_kannada",
    title:"Only Kannada",
    description:"Only Kannada progressive web app",
    keywords:"Only Kannada, webapp, movies"
 },
]
