import store from "../store";

let _googleId = null;
let playerConfig=null;
let pushConfiguration=[];


export default (vendorData,configData) => {

  _googleId=vendorData._googleId;
  playerConfig={...vendorData.playerConfig};
  pushConfiguration=[...vendorData.pushConfiguration];
  store.commit("CommitVendorAssets", vendorData.vendorAssets);
  document.querySelector("body").setAttribute("id", vendorData.bodyID);

  vendorData.colorCodes && Object.entries(vendorData.colorCodes).forEach(([prop,value])=>{
    document.querySelector("body").style.setProperty(prop, value);
  })
  
  playerConfig.tokenApiURL=configData.baseUrl+'/';
  if(playerConfig.watermark){
    playerConfig.watermark=require(`@/assets/icons/logo/${playerConfig.watermark}/watermark.svg`);
  }
}




export { _googleId, playerConfig, pushConfiguration };