import { mapGetters } from 'vuex';
import firebase from "firebase/app";
import "firebase/analytics";

const GoogleAnalytics = {
    data() {
        return {
            googleAnalytics: null
        }
    },
    created() {
        let secondaryFirebase = firebase.app("secondary");
        this.googleAnalytics = secondaryFirebase.analytics();
    },
    computed: {
        ...mapGetters(["subscriberId", "country", "appUser"])
    },
    methods: {
        signUpEvent(data) {
            if (!this.googleAnalytics) return;
            let analyticsData = this.commonData(data);
            analyticsData.EventCategory = 'REGISTER';
            this.googleAnalytics.logEvent("SignUp", analyticsData);
        },
        unsubscribeEvent(data) {
            if (!this.googleAnalytics) return;
            let analyticsData = this.commonData(data);
            analyticsData.EventCategory = 'REGISTER';
            this.googleAnalytics.logEvent("UnSubscribe", analyticsData);
        },
        loginEvent(data) {
            if (!this.googleAnalytics) return;
            let analyticsData = this.commonData(data);
            analyticsData.EventCategory = 'LOGIN';
            this.googleAnalytics.logEvent("SignIn", analyticsData);
        },
        logoutEvent(data) {
            if (!this.googleAnalytics) return;
            this.unsubscribeEvent(data);
            let analyticsData = this.commonData(data);
            analyticsData.EventCategory = 'LOGIN';
            this.googleAnalytics.logEvent("Logout", analyticsData);
        },
        MenuEvent(data) {
            if (!this.googleAnalytics) return;
            let analyticsData = this.commonData(data);
            analyticsData.EventCategory = 'NAVIGATION';
            this.googleAnalytics.logEvent('MenuSelected', analyticsData);
        },
        ContentDetails(data) {
            if (!this.googleAnalytics) return;
            let analyticsData = this.commonData(data);
            analyticsData.EventCategory = 'NAVIGATION';
            this.googleAnalytics.logEvent('ContentDetails', analyticsData);
        },
        ContentPlaybackEvent(data) {
            if (!this.googleAnalytics) return;
            let analyticsData = this.commonData(data);
            analyticsData.EventCategory = "PLAYER";
            this.googleAnalytics.logEvent("ContentPlayback", analyticsData);
        },
        SeriesWatchEvent(data) {
            if (!this.googleAnalytics) return;
            let analyticsData = this.commonData(data);
            analyticsData.EventCategory = "SERIES";
            this.googleAnalytics.logEvent("SeriesWatching", analyticsData);
        },
        ContentSearchEvent(data) {
            if (!this.googleAnalytics) return;
            let analyticsData = this.commonData(data);
            analyticsData.EventCategory = "SEARCH";
            this.googleAnalytics.logEvent("ContentSearch", analyticsData);
        },
        TrailerWatchingEvent(data) {
            if (!this.googleAnalytics) return;
            let analyticsData = this.commonData(data);
            analyticsData.EventCategory = "TRAILER";
            this.googleAnalytics.logEvent("TrailerWatching", analyticsData);
        },
        ContentPurchaseEvent(data) {
            if (!this.googleAnalytics) return;
            let analyticsData = this.commonData(data);
            analyticsData.EventCategory = "PURCHASE";
            this.googleAnalytics.logEvent("ContentPurchase", analyticsData);
        },
        PlanPurchaseEvent(data) {
            if (!this.googleAnalytics) return;
            let analyticsData = this.commonData(data);
            analyticsData.EventCategory = "PLAN";
            this.googleAnalytics.logEvent("PlanPurchase", analyticsData);
        },
        ScreenViewEvent(data) {
            if (!this.googleAnalytics) return;
            let analyticsData = this.commonData(data);
            analyticsData.EventCategory = "NAVIGATION";
            this.googleAnalytics.logEvent("ScreenViewed", analyticsData);
        },
        commonData(data) {
            if (this.country) {
                data.country = this.country;
            }
            if (this.subscriberId) {
                data.subscriberid = this.subscriberId
                data.UserMode = 'REGISTERED'
            } else {
                data.UserMode = 'GUEST'
            }
            if (this.appUser) {
                data.email = this.appUser.email;
            }
            return data;
        }
    }
}
export default GoogleAnalytics;