import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/components/Home/home.vue';
import Profile from '@/components/Profile/profile.vue';
// import ContentDeeplink from '@/components/ContentDeeplink/contentDeeplink.vue';
import PaymentConfirm from '@/components/Popups/payment/paymentConfirm.vue'

const ContentList=()=>import('@/components/ContentList/contentList.vue');
const WatchList=()=>import('@/components/WatchList/watchList.vue');
const SearchList=()=>import('@/components/SearchList/searchList.vue');
const DetailCard=()=>import('@/components/Shared/templates/detailCard.vue');
const Promotions=()=>import('@/components/Promotions/promotions.vue');

// const ContentList = (resolve) => {
//   require.ensure(['@/components/ContentList/contentList.vue'], () => {
//     resolve(require('@/components/ContentList/contentList.vue'))
//   }, 'Route-ContentList');
// };
// const WatchList = (resolve) => {
//   require.ensure(['@/components/WatchList/watchList.vue'], () => {
//     resolve(require('@/components/WatchList/watchList.vue'))
//   }, 'Route-WatchList');
// };
// const SearchList = (resolve) => {
//   require.ensure(['@/components/SearchList/searchList.vue'], () => {
//     resolve(require('@/components/SearchList/searchList.vue'))
//   }, 'Route-SearchList');
// };
// const DetailCard=(resolve) =>{
//   require.ensure(['@/components/Shared/templates/detailCard.vue'],()=>{
//     resolve(require('@/components/Shared/templates/detailCard.vue'))
//   },'Route-DetailCard');
// }

// const Promotions=resolve=>{
//   require.ensure(['@/components/Promotions/promotions.vue'],()=>{
//     resolve(require('@/components/Promotions/promotions.vue'))
//   },'Route-Promotions');
// }

Vue.use(Router)

export default new Router({
  routes: [

    {
      path: '/profile',
      name: 'Profile',
      component: Profile
    },
    // {
    //   path: '/content/:contentid',
    //   name: 'ContentDeeplink',
    //   component: ContentDeeplink,
    //   props: true
    // },
    {
      path: '/contentList/:contentType',
      name: 'ContentList',
      component: ContentList,
      children:[
        {
          path:"detail-card/:contentid",
          name:'ContentList/Card',
          component:DetailCard  
        }
      ]
    },
    {
      path: '/watchList',
      name: 'WatchList',
      component: WatchList,
      children:[
        {
          path:"detail-card/:contentid",
          name:'WatchList/Card',
          component:DetailCard  
        }
      ]
    },
    {
      path: '/search/:query',
      name: 'SearchList',
      component: SearchList,
      children:[
        {
          path:"detail-card/:contentid",
          name:'SearchList/Card',
          component:DetailCard  
        }
      ]
    },
    {
      path:'/poster-promo',
      name:'Promotions',
      component:Promotions,
      meta:{
        ignore: true
      }
    },
    {
      path: '/confirm/:query',
      name: 'PaymentConfirm',
      component:PaymentConfirm
    },
    {
      path: '/:link?',
      name: 'Home',
      component: Home,
      children:[
        {
          path:"detail-card/:contentid",
          name:'Home/Card',
          component:DetailCard  
        }
      ]   
    },
    
  ],
  mode: 'history'
})
