
const state = {
  FCM_BASE_URL: "https://iid.googleapis.com/iid/v1",
  FCM_TOPIC_NAME_PREFIX: "MS_W_",

  FAQ_URL: 'https://vboxvendor.mobiotics.com/subscriber/faq/entertainmentapp_faq.json',
  SECRET_KEY: "vLite6",

  //firebase messaging
  FirebaseConfig: {
    serverKey: null,
    certificateKey: null
  }

};

const getters = {
  // FAQ_URL: state => {
  //   return state.FAQ_URL;
  // },
  SECRET_KEY: state => {
    return state.SECRET_KEY;
  },
  FCM_BASE_URL: (state) => {
    return state.FCM_BASE_URL;
  },
  // FIRE_API_KEY: (state) => {
  //   return state.FIRE_API_KEY;
  // },
  FCM_TOPIC_NAME_PREFIX: (state) => {
    return state.FCM_TOPIC_NAME_PREFIX;
  },
 
  FirebaseConfig: ( state ) => {
    return state.FirebaseConfig ;
  }

};

const mutations = {
  CommitFirebaseConfig: (state, content) => {
    state.FirebaseConfig = content;
  }
}

export const Consts = { state, getters, mutations };
