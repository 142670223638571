import Vue from "vue";

let cookies_permission=localStorage.getItem('cookies_permission')==='true'?true:false;
const state = {
  contentList: null,
  sliderCategory: null,
  contentType: null,
  contentData:{},
  contentSubscriber:null,
  appRouteHistory:[],
  playerContent:null,
  cookiePermission:cookies_permission
};

const getters = {
  contentList: state => state.contentList,
  sliderCategory: state => state.sliderCategory,
  contentType: state => state.contentType,
  contentSubscriber:state=>state.contentSubscriber,
  contentData:state=>(contentid)=>state.contentData[contentid],
  appRouteHistory:state=>([...state.appRouteHistory]),
  playerContent:state=>state.playerContent,
  cookiePermission:state=>state.cookiePermission
};

const mutations = {
  commitContentList: (state, content) => {
    state.contentList = content;
  },
  commitSliderCategory: (state, category) => {
    state.sliderCategory = category;
  },
  commitContentType: (state, contenttype) => {
    state.contentType = contenttype;
  },
  commitContentData:(state,payload)=>{
    if(state.contentData[payload.contentid]){
    state.contentData[payload.contentid]={...state.contentData[payload.contentid],...payload.data};
    }
    else {
      state.contentData[payload.contentid]={...payload.data};
    }
  },
  commitContentSubscriber:(state,data)=>{
    state.contentData={};
    state.contentSubscriber=data;
  },
  commitRouteHistory:(state,data)=>{
    let history=state.appRouteHistory;
    if(history.length>2)history.shift();
    state.appRouteHistory=[...history,data];  
  },
  commitPlayerContent:(state,data)=>{
    state.playerContent=data;  
  },
  commitCookiePermission:(state,data)=>{
    state.cookiePermission=data;
  }
};

const actions = {
  actListContents: (context, data) => {
    let baseUrl = context.getters.baseUrl;
    let subscriberUrl = context.getters.subscriberUrl;
    let vendorid = context.getters.vendorId;
    let vendorkey = context.getters.vendorKey;
    let country = context.getters.country;
    if (country === null || country === undefined) {
      country = JSON.parse(localStorage.getItem("country"));
    }
    return new Promise((resolve, reject) => {
      Vue.http.get(baseUrl + `/${subscriberUrl}/content`, {
        params: {
          vendorid: vendorid,
          vendorkey: vendorkey,
          country: country,
          ...data
        }
      }).then(response => {
        if (response.ok && response.status === 200) {
          resolve(response.body);
        } else {
          reject({
            error: true,
            status: response.status,
            statusText: response.statusText
          });
        }
      })
        .catch(error => {
          reject(error);
        });
    });
  },
  actListPremiers:(context,data)=>{
    let baseUrl = context.getters.baseUrl;
    let subscriberUrl = context.getters.subscriberUrl;
    let vendorid = context.getters.vendorId;
    let vendorkey = context.getters.vendorKey;
    let country = context.getters.country;
    if (country === null || country === undefined) {
      country = JSON.parse(localStorage.getItem("country"));
    }
    return new Promise((resolve, reject) => {
      Vue.http.get(baseUrl + `/${subscriberUrl}/content/promoted`, {
        params: {vendorid, vendorkey, country,...data}
      }).then(response => {
        if (response.ok && response.status === 200) {
          resolve(response.body);
        } else {
          reject({
            error: true,
            status: response.status,
            statusText: response.statusText
          });
        }
      })
        .catch(error => {
          reject(error);
        });
    });
  },
  actListCategories: (context, data) => {
    let baseUrl = context.getters.baseUrl;
    let subscriberUrl = context.getters.subscriberUrl;
    let vendorid = context.getters.vendorId;
    let vendorkey = context.getters.vendorKey;
    return new Promise((resolve, reject) => {
      Vue.http.get(baseUrl + `/${subscriberUrl}/content/category`, {
        params: {
          vendorkey: vendorkey,
          vendorid: vendorid,
          ...data
        }
      }).then(response => {
        if (response.ok && response.status === 200) {
          resolve(response.body);
        } else {
          reject({
            error: true,
            status: response.status,
            statusText: response.statusText
          });
        }
      })
        .catch(error => {
          reject(error);
        });
    });
  },
  actGetContent: (context, contentId) => {
    let baseUrl = context.getters.baseUrl;
    return new Promise((resolve, reject) => {
      let appConfig = JSON.parse(localStorage.getItem("appConfig"));
      if (!appConfig) {
        reject("Missing App Config Data");
      }

      Vue.http
        .get(baseUrl + "/subscriber/v1/content/" + contentId, {
          params: {
            vendorid: appConfig.vendorDetails.vendorId,
            vendorkey: appConfig.vendorDetails.vendorKey
          }
        })
        .then(response => {

          if (response.ok && response.status === 200) {
            resolve(response.body);
          } else {
            reject({
              error: true,
              status: response.status,
              statusText: response.statusText
            });
          }
        })
        .catch(error => {

          reject(error);
        });
    });
  },
  actListTrailerContents: (context, data) => {
    let baseUrl = context.getters.baseUrl;
    let subscriberUrl = context.getters.subscriberUrl;
    let vendorid = context.getters.vendorId;
    let vendorkey = context.getters.vendorKey;
    let contentid = data.contentid;
    delete data.contentid
    return new Promise((resolve, reject) => {
      Vue.http.get(baseUrl + `/${subscriberUrl}/content/${contentid}`, {
        params: {
          vendorid: vendorid,
          vendorkey: vendorkey,
          ...data
        }
      }).then(response => {
        if (response.ok && response.status === 200) {
          const content = response.body;
          Array.isArray( content?.trailer ) && content.trailer.forEach( trailer => {
             trailer.posters = content.posters ?? [];
             trailer.title = trailer.title || content.title; 
          })
          resolve(response.body);
        } else {
          reject({
            error: true,
            status: response.status,
            statusText: response.statusText
          });
        }
      })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const Content = { state, getters, mutations, actions };
