import { Mainplayer } from 'video-player';

const state = {
  playerInstance: null,
  settings: {
    viewInjected: true
  }
};

const getters = {

  playerInstance: state => {
    return state.playerInstance;
  },


};

const mutations = {
  commitPlayerSettings: (state, { widevineLicenseURL }) => {
    state.settings = {
      ...state.settings,
      widevineLicenseURL
    }
  },
  commitPlayerInstance: (state) => {
    state.playerInstance = new Mainplayer({
      settings: state.settings
    });
  },
}

export const Player = { state, getters, mutations };
