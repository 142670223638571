<template>
  <popup :width="width" :heightPopup="height" :closePopupEvent="closePopupCB">
    <div class="session-exp-popup">
      <div class="popup-form">
        <div>
          <img
            v-if="isSuccess"
            class="image"
            src="@/assets/icons/tick.svg"
            alt="tick"
            loading="lazy"
          />
          <img v-else class="image" src="@/assets/icons/wrong.svg" alt="fail" loading="lazy" />
        </div>
        <div>
          <p v-if="isSuccess" class="success-title">Success</p>
          <p v-else class="failed-title">Failed</p>

          <p class="message">{{message}}</p>

          <p v-if="isSuccess" class="transaction">Transaction ID: {{transactionId}}</p>
        </div>
        <div class="reset-btn">
          <button
            type="submit"
            class="btn-login"
            ref="loginBtnSubmit"
            @click="actSubmit"
          >{{btnName}}</button>
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";
import GoogleAnalytics from "@/mixins/GoogleAnalytics";
import SimpleCrypto from "simple-crypto-js";

export default {
  name: "popSuccess",
  data() {
    return {
      width: "35%",
      height: "55%",
      isSuccess: null,
      message: "",
      transactionId: "",
      btnName: "",
      paytminitpage: "",
      inputDataset: [],
      expiryDate: null,
      flagAutologin:true
    };
  },
  computed: {
    ...mapGetters(["SECRET_KEY"])
  },
  methods: {
    ...mapActions(["actPayment", "actGetPlans"]),
    ...mapMutations(["commitPlansSubscribedResponse"]),
    closePopupCB() {
      if (this.btnName === "DONE") {
        this.$router.push({ name: "Home" });
      } else if (this.btnName === "RETRY") {
        let localFromPage = JSON.parse(localStorage.getItem("frompage"))
          ? JSON.parse(localStorage.getItem("frompage"))
          : {frompage:"/"};
        this.$router.push({ path: localFromPage.frompage });
      }
    },
    actSubmit() {
      if (this.btnName === "DONE") {
        this.closePopupCB();
        this.purchasedPlans();
      } else if (this.btnName === "RETRY") {
        let localData = JSON.parse(localStorage.getItem("retry"))
          ? JSON.parse(localStorage.getItem("retry"))
          : null;
        if (localData) {
          EventBus.$emit("paymentInitPopup", localData);
        }
        this.closePopupCB();
      }
    },
    paymentAnalytics() {
      let analyticsData={};
      let localData = JSON.parse(localStorage.getItem("retry"));
      let localStorageAmount = JSON.parse(localStorage.getItem("amount"));
      if (localData.paymentdata.content.contentid) {
        analyticsData.ContentTitle = localData.paymentdata.content.title;
        analyticsData.ContentID = localData.paymentdata.content.contentid;
        analyticsData.Category = localData.paymentdata.content.category;
        analyticsData.ContentType = localData.paymentdata.content.contenttype;
      } else if (localData.paymentdata.content.planid) {
        analyticsData.PlanID = localData.paymentdata.content.planid;
        analyticsData.PlanName = localData.paymentdata.content.planname;
        analyticsData.PlanType = localData.paymentdata.content.plantype;
        if (localData.paymentdata.subscriptiontype === "NEW") {
          analyticsData.PurchaseType = "NORMAL";
        } else {
          analyticsData.PurchaseType = localData.paymentdata.subscriptiontype;
        }
      }
      analyticsData.Recurring = "FALSE";
      analyticsData.Expiry = this.expiryDate;
      analyticsData.Amount = localStorageAmount.amount / 100;
      analyticsData.Currency = localStorageAmount.currency;
      analyticsData.GWProvider = "PAYTM";

      if (localData.paymentdata.content.seriesid) {
        analyticsData.SeriesID = localData.paymentdata.content.seriesid;
        analyticsData.SeriesTitle = localData.paymentdata.content.title;
      }
      analyticsData.Quality = localData.paymentdata.content.selectedtype;

      if (this.$route.query.success) {
        analyticsData["Event Label"] = "Success";
        analyticsData.PaymentStatus = "SUCCESS";
        if (localData.paymentdata.content.planid) {
          analyticsData.TransactionId = this.$route.query.success;
        } else {
          analyticsData.TicketID = this.$route.query.success;
        }
      } else {
        analyticsData["Event Label"] = "Failure";
        analyticsData.PaymentStatus = "FAILURE";
        analyticsData.ErrorCode = this.$route.query.error;
        analyticsData.ErrorMessage =
          "Unfortunately your transaction has failed. Please try again";
      }
      if (localData.paymentdata.content.contentid) {
        this.ContentPurchaseEvent(analyticsData);
      } else if (localData.paymentdata.content.planid) {
        this.PlanPurchaseEvent(analyticsData);
      }
    },
    purchasedPlans() {
      let data = {};

      data.subscriberid = this.subscriberId;
      this.actGetPlans(data)
        .then(response => {
          if (!response.error) {
            this.erpiryDate = response.expirydate;
            this.commitPlansSubscribedResponse(response);

            if (localStorage.getItem("loginType")) {
              let loginType = JSON.parse(localStorage.getItem("loginType"));
              if (loginType.type === "EMAIL") {
                let simpleCrypto = new SimpleCrypto(this.SECRET_KEY);
                let tempPassDec = simpleCrypto.decrypt(loginType.password);
                EventBus.$emit(
                  "loginUser",
                  {
                    email: loginType.email,
                    password: tempPassDec,
                    app: "SELF"
                  },
                  "L"
                );
              }
            }
          }
        })
        .catch(error => {
          console.log("error ", error);
          if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.purchasedPlans);
              this.flagAutologin = false;
              return;
            }
          }
        });
      this.paymentAnalytics();
    }
  },
  created() {
    console.log("entered payment confirm ", this.$route.query);

    if (this.$route.query.success) {

      // this.commonAutoLogin()
      // console.log("entered success");
      this.isSuccess = true;
      let localStorageAmount;
      if (localStorage.getItem("amount")) {
        localStorageAmount = JSON.parse(localStorage.getItem("amount"));
        let currency;
        if (localStorageAmount.currency === "INR") {
          currency = "₹";
        } else {
          currency = "$";
        }
        this.message =
          "Your payment of " +
          currency +
          localStorageAmount.amount / 100 +
          " been processed successfully";
      }

      if (this.$route.query.transactionid) {
        this.transactionId = this.$route.query.transactionid;
      }
      this.btnName = "DONE";
    } else if (this.$route.query.error) {
      this.message =
        "Unfortunately your transaction has failed. Please try again";
      this.btnName = "RETRY";
      this.paymentAnalytics();
    }
  },
  components: {
    popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue")
  },
  mixins: [appMixins, GoogleAnalytics]
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";

.session-exp-popup {
  text-align: center;
  .popup-form {
    padding: 5% 26%;
    .image {
      width: 25%;
    }
    .message {
      color: $font-clr-gray;
      font-family: $font-family;
      font-weight: $font-weight-medium;
      margin: 10% auto;
    }
    .transaction {
      color: $font-clr-white-1;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 10px;
      margin-bottom: 5%;
    }
    .success-title {
      color: $font-clr-blue;
      font-family: $font-family;
      font-weight: $font-weight-semibold;
      font-size: 1.4rem;
    }
    .failed-title {
      color: $font-clr-red;
      font-family: $font-family;
      font-weight: $font-weight-semibold;
      font-size: 1.4rem;
    }
  }
}
</style>