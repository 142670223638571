import Vue from "vue";
// import { app } from "firebase";
const playback_config = [{
  "initDataTypes": ["cenc"],
  "audioCapabilities": [{
    "contentType": "audio/mp4;codecs=\"mp4a.40.2\""
  }],
  "videoCapabilities": [{
    "contentType": "video/mp4;codecs=\"avc1.42E01E\""
  }]
}];
const state = {
  baseUrl: null,
  appUser: null,
  email: null,
  picture: null,
  appConfig: null,
  appMessages: undefined,
  appMenu: undefined,
  appScreens: undefined,
  appVendor: null,
  appTheme: "",
  subscriberId: "",
  vendorId: null,
  vendorKey: null,
  vendorAssets: null,
  supportEmail: "",
  currency: null,
  subscriberUrl: null,
  drmUrl: null,
  cdmData:null
};

const getters = {
  drmUrl: state => {
    return state.drmUrl;
  },
  subscriberUrl: state => {
    return state.subscriberUrl;
  },
  baseUrl: state => {
    return state.baseUrl;
  },
  appUser: state => {
    return state.appUser;
  },
  email: state => {
    return state.email;
  },
  picture: state => {
    return state.picture;
  },
  appConfig: state => {
    return state.appConfig;
  },
  appMessages: state => {
    return state.appMessages;
  },
  appMenu: state => {
    return state.appMenu;
  },
  appScreens: state => {
    return state.appScreens;
  },
  appVendor: state => {
    return state.appVendor;
  },
  appTheme: state => {
    return state.appTheme;
  },
  subscriberId: state => {
    return state.subscriberId;
  },
  vendorId: state => {
    return state.vendorId;
  },
  vendorKey: state => {
    return state.vendorKey;
  },
  vendorAssets: state => {
    return state.vendorAssets;
  },
  supportEmail: state => {
    return state.supportEmail;
  },
  currency: state => {
    return state.currency;
  },
  cdmData:state=>{
    return state.cdmData;
  }
};

const mutations = {
  CommitConfigStore: state => {
    if (localStorage.getItem("appConfig")) {
      let appConfig = (state.appConfig = JSON.parse(
        localStorage.getItem("appConfig")
      ));

      state.baseUrl = appConfig.vendorDetails.baseUrl;
      // state.baseUrl = "https://gogotest.mobiotics.com/"
      state.drmUrl = appConfig.vendorDetails.drmUrl;
      state.subscriberUrl = appConfig.apiUtils.subscriberUrl = "subscriber/v1";
      state.appTheme = appConfig.vendorDetails.defaultTheme;
      state.appMessages = appConfig.messageList;
      state.appMenu = appConfig.menu;
      state.appScreens = appConfig.screens;
      state.appConfig = appConfig;
      if (appConfig.vendorDetails.vendorId) {
        state.vendorId = appConfig.vendorDetails.vendorId;
      }
      if (appConfig.vendorDetails.vendorKey) {
        state.vendorKey = appConfig.vendorDetails.vendorKey;
      }
    }
  },
  CommitUserStore: state => {
    if (localStorage.getItem("appUser")) {
      let appUser = (state.appUser = JSON.parse(
        localStorage.getItem("appUser")
      ));

      if (appUser.subscriberid) {
        state.subscriberId = appUser.subscriberid;
      }
      if (appUser.email) state.email = appUser.email;
      if (appUser.picture) state.picture = appUser.picture;
    } else {
      state.appUser = null;
      state.subscriberId = null;
      state.email = null;
      state.picture = null;
    }
  },
  CommitAppUser:(state,data)=>{
      if(data && data.constructor===Object){
        state.appUser={...state.appUser,...data};
      }
  },
  CommitVendorStore: state => {
    if (localStorage.getItem("appVendor")) {
      let appVendor = (state.appVendor = JSON.parse(
        localStorage.getItem("appVendor")
      ));

      state.appVendor = appVendor;

      if (appVendor.supportmail) {
        state.supportEmail = appVendor.supportmail;
      }
    }
  },
  CommitCurrencyToStore: (state, data) => {
    state.currency = data;
  },
  CommitVendorAssets: (state, data) => {
    state.vendorAssets = data;
  },
  CommitCdmData:(state,data={})=>{
      state.cdmData={...data};
  }
};

const actions = {
  // CommitLocalStore: context => {
  //   context.commit("CommitUserStore");
  //   context.commit("CommitConfigStore");
  //   context.commit("CommitVendorStore");
  // },
  actCDMSupport:async (context)=>{
      let browserSupport;
      if ("userAgent" in navigator) {
        const userAgent = navigator.userAgent;
        const versionRegex = /Chrome\/(?<chrome>[\d]+)|Firefox\/(?<firefox>[\d]+)/;
        const browserVersions= userAgent.match(versionRegex)?.groups;
        const supportedBrowsers=context.getters.appConfig?.supportedBrowsers;
        
        if(!browserVersions || !Array.isArray(supportedBrowsers))return (browserSupport=true);
        
        browserSupport=supportedBrowsers.every(({browser,version})=>{
            let currentVersion=browserVersions[browser];
            return currentVersion?Number(currentVersion)>=version:true;
        })

      }
      try { 
          await navigator.requestMediaKeySystemAccess("com.widevine.alpha", playback_config)
                
          context.commit('CommitCdmData',{playback:true,widevine:true,browserSupport});
                
      } 
      catch (e) {
          context.commit('CommitCdmData',{playback:false,widevine:false,browserSupport});
      }

  },
  actLocate: context => {
    context.commit("CommitConfigStore");
    let appConfig = context.getters.appConfig;
    let vendorId = appConfig.vendorDetails.vendorId;
    let vendorKey = appConfig.vendorDetails.vendorKey;
    if (!(vendorId && vendorKey)) {
      return;
    }

    let baseUrl = context.getters.baseUrl;
    let params = { vendorId, vendorKey };
    let url = `${baseUrl}/subscriber/v1/locate`;

    return new Promise(function (resolve, reject) {
      Vue.http
        .get(url, { params })
        .then(response => {

          if (response.ok && response.status === 200) {
            resolve(response.body);
          } else {
            reject({ error: true });
          }
        })
        .catch(error => {

          reject(error);
        });
    });
  },
  getFaqJSON(context) {
    // let faqURL = context.getters.FAQ_URL;

    let localData = JSON.parse(localStorage.getItem("appConfig"));
    let faqURL = localData.vendorDetails.faqUrl;
    return new Promise(function (resolve, reject) {
      // Vue.http.get(faqURL).then(response => {
        fetch(faqURL,{
          method:'GET'
        }).then(response =>{
          resolve(response)
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  actNotifyVendor: (context, data) => {
    // Define the API URL
    let URL = `${context.getters.baseUrl}/${context.getters.subscriberUrl}/notify`;

    return new Promise(function (resolve, reject) {
      let params = {
        ...data
      };
      Vue.http
        .post(URL, params, {
          emulateJSON: true
        })
        .then(response => {
          if (response.ok && response.status === 200) {
            resolve(response.body);
          } else {
            reject({ error: true });
          }
        })
        .catch(error => {

          reject(error);
        });
    });
  }
};

export const Master = { state, getters, mutations, actions };
