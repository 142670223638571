/* eslint-disable new-cap */
/* eslint-disable no-new */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "@/base-configurations/configuration.js";
import './registerServiceWorker'
import Vue from 'vue'
import VueResource from 'vue-resource'
// import VueScrollTo from 'vue-scrollto'
import { appClass } from './module/app-class.js'
import {SlideBar} from 'vue-video-controls';


if (!customElements.get("slide-bar")) {
  customElements.define("slide-bar", SlideBar);
}
Vue.config.ignoredElements=[
  'slide-bar'
]

Vue.config.productionTip = false

Vue.use(VueResource)
Vue.http.options.credentials = true

// Vue.use(VueScrollTo)
export const EventBus = new Vue()

new appClass()
