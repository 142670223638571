import pageRoutes from '@/base-configurations/seo_routes';
export let getEl=(selector,parent=document)=>parent.querySelector(selector);

/** used to create elements. 
 * Expects:
 * @param {String} name: tagname of element to be created.
 * @param {Array of [property,value]} tags: attributes to be created.
 * @return {HTMLElement}   
 * */
export let createTag=(name,tags=[])=>{
    let el=document.createElement(name);
    tags.forEach(([attr,val])=>{
      el.setAttribute(attr,val);
    })
    return el;
};  



/** used to create web schema. 
 * Expects:
 * @param {Object} data : expects website name and url.
 * @param {HTMLElement} headEl: head element of document.   
 * */
export let createSchema=(data,headEl)=>{

    let WebSiteScript=createTag('script',[['type','application/ld+json']]);
    let webSchemaJSON= JSON.stringify({
      "@context":"http://schema.org",
      "@type":"WebSite",
      "name":data.name,
      "url":data.url
    });
    WebSiteScript.innerText=webSchemaJSON;
    headEl.append(WebSiteScript);
  }

// export let createFBTags=(data={}, headEl)=>{
   
//     let getAttr=([tagname,content])=>{
//         let attrs=[['property',`og:${tagname}`],['content',content]];
//         return createTag('meta',attrs);
//     };
    
//     let og_elements=Object.entries(data).map(getAttr);

//     headEl.append(...og_elements);


//     return (props={...data})=>{
//         Object.entries(props).forEach(([metaname,metacontent])=>{
//                let metaelement=getEl(`[property='og:${metaname}']`,headEl);
//                if(metaelement){
//                    metaelement.content=metacontent;
//                }
//         })
//     }

// }

let routeRegex=null;
export let routePageUpdate=(path=window.location.pathname)=>{
  let location=window.location.origin+path;
  let routeData=pageRoutes.find(({url})=>url.test(location));

  if(!routeData)return;
  if(routeData.url.toString() === routeRegex)return;//return if same route...

  routeRegex=routeData.url.toString();

  let {title, description, keywords}=routeData;

  document.title=title;
  let titleElem=document.querySelector('head>title');
  let descElem=document.querySelector('head>meta[name="description"]');
  let keywordsElem=document.querySelector('head>meta[name="keywords"]');

  titleElem.innerText=title;
  descElem.content=description;
  keywordsElem.content=keywords;

}