<template>
  <div class="vlt-profile-container">
    <div class="profile-details">
      <div class="profile-img">
        <img
          v-if="picture"
          :src="picture"
          alt="vlt-prof"
          class="profile-img-responsive"
        />
        <img
          v-else
          :src="defaultPicture"
          alt="vlt-prof"
          class="profile-img-responsive"
          loading="lazy"
        />
      </div>
      <div class="profile-details-sub">
        <p class="name">
          {{ name }}
        </p>
        <p class="email">{{ email }}</p>
      </div>
      <div class="profile-details-sub-1">
        <div class="gender-dob">
          <p class="title">Gender</p>
          <p class="details">{{ gender }}</p>
        </div>
        <div class="gender-dob">
          <p class="title">DOB</p>
          <p class="details">{{ dob }}</p>
        </div>
      </div>
      <div class="profile-details-sub-2">
        <button
          class="edit-change"
          :class="toggleEditPopup ? 'active-edit-change' : ''"
          @click="toggleEditProfilePopup(true)"
        >
          Edit profile
        </button>
        <button
          class="edit-change"
          :class="toggleChangePassPopup ? 'active-edit-change' : ''"
          @click="toggleChangePasswordPopup(true)"
          v-show="!issocialLogin"
        >
          Change password
        </button>
      </div>
      <div class="profile-details-sub-3">
        <button
          class="edit-change"
          :class="toggleDeletePopup ? 'active-edit-change' : ''"
          @click="toggleDeletesubscriberPopup(true)"
        >
          Delete Account
        </button>
        <img src="@/assets/icons/delete-icon.svg" />
      </div>
    </div>
    <div class="profile-setting" ref="purchasedParent">
      <div class="card-tab">
        <a
          class="tab-link"
          v-for="(tab, i) in cardTabs"
          :key="i"
          :class="tab === activeTabName ? 'activetabname' : ''"
          @click="renderTab(tab)"
          :href="'#' + activeTabName.toLowerCase()"
        >
          <span>{{ tab }}</span>
        </a>
      </div>
      <div class="tab-details">
        <!-- <component :is="activeTabName"  :purchasedDetails="activeTabName == 'Purchased' ? purchasedDetails: ''" ></component> -->
        <component
          :is="activeTabName"
          :purchasedDetails="purchasedDetails"
          :purchasedParent="$refs.purchasedParent"
        ></component>
      </div>
    </div>
    <EditProfilePopup
      v-if="toggleEditPopup"
      :closePopupCB="() => toggleEditProfilePopup(false)"
      :editData="editData"
    ></EditProfilePopup>
    <ChangePasswordPopup
      v-if="toggleChangePassPopup"
      :closePopupCB="() => toggleChangePasswordPopup(false)"
      :subscriberid="subscriberid"
    ></ChangePasswordPopup>
    <DeletePopup
      v-if="toggleDeletePopup"
      :closePopupCB="() => toggleDeletesubscriberPopup(false)"
      :appname="this.appname"
    >
    </DeletePopup>
    <PaymentcancelPopup
      v-if="togglePaymentPopup"
      :closePopupCB="() => toggleDeletesubscriberPopup(false)"
      :message="this.message"
    >
    </PaymentcancelPopup>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";

export default {
  data() {
    return {
      defaultPicture: require("@/assets/icons/user-default.svg"),
      picture: undefined,
      cardTabs: [],
      activeTabName: "",
      name: "",
      dob: "",
      gender: "",
      email: "",
      toggleEditPopup: false,
      toggleChangePassPopup: false,
      toggleDeletePopup: false,
      togglePaymentPopup: false,
      editData: undefined,
      subscriberid: "",
      purchasedDetails: undefined,
      planStatus: "",
      flagAutologin: true,
      renewMode: "",
      appname: "",
      issocialLogin:false
    };
  },
  watch: {
    activeTabName(val) {
      if (val == "Purchased") {
        this.getPurchasedDetails();
      }
    },
    purchasedDetails(val) {
      this.purchasedDetails = val;
    },
  },
  methods: {
    ...mapActions(["actGetSubscriber", "actGetTicket"]),
    ...mapMutations(["CommitUserStore"]),
    actSetCardTab() {
      let localAppConfig = JSON.parse(localStorage.getItem("appConfig"))
        ? JSON.parse(localStorage.getItem("appConfig"))
        : null;
      if (
        localAppConfig &&
        localAppConfig.featureEnabled &&
        localAppConfig.featureEnabled.subscription
      ) {
        this.cardTabs.push("Plans");
      }
      if (
        localAppConfig &&
        localAppConfig.featureEnabled &&
        localAppConfig.featureEnabled.purchase
      ) {
        this.cardTabs.push("Purchased");
      }
      this.cardTabs.push("Settings");

      console.log("route ", this.$route);
      if (this.$route.hash === "#settings") {
        this.activeTabName = this.cardTabs[2];
      } else if (this.$route.hash === "#purchased") {
        this.activeTabName = this.cardTabs[1];
      } else {
        this.activeTabName = this.cardTabs[0];
      }
    },
    renderTab(tab) {
      this.activeTabName = tab;
    },
    getProfileDetails() {
      let localData = JSON.parse(localStorage.getItem("loginType"));
      if (!localData) {
        window.location.assign(window.location.origin);
      }
      let param = {};
      param.email = localData.email;

      this.actGetSubscriber(param)
        .then((response) => {
          console.log("MYS", response);
          this.subscriberid = response.subscriberid;
          this.editData = response;
          this.name = response.subscribername;
          this.email = response.email;
          this.renewMode = response?.renewmode;
          this.planStatus = response?.planstatus;
          if (response.gender) {
            this.gender = response.gender;
          } else {
            this.gender = "---";
          }

          if (response.dob) {
            let tempDob = response.dob.split("-");
            this.dob = tempDob[2] + "-" + tempDob[1] + "-" + tempDob[0];
          } else {
            this.dob = "---";
          }

          if (response.picture) {
            this.picture = response.picture;
            let appUser = JSON.parse(localStorage.getItem("appUser"));
            appUser.picture = response.picture;
            let appUserString = JSON.stringify(appUser);
            localStorage.setItem("appUser", appUserString);
            this.CommitUserStore();
          }

          this.planStatus = response.planstatus;
        })
        .catch((error) => {
          console.log(error);
          if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.getProfileDetails);
              this.flagAutologin = false;
              return;
            }
          }
        });
    },
    getPurchasedDetails() {
      this.actGetTicket()
        .then((response) => {
          console.log("tickets response ", response);
          this.purchasedDetails = [];
          response.forEach((element) => {
            if (element.status === "ACTIVE") {
              // let currentDate = new Date();
              // let expiryDate = new Date(element.expiry);
              if (element.expiry && !this.checkEventEnded(element.expiry)) {
                this.purchasedDetails.push(element);
              }
            }
          });
          console.log("purchased details ", this.purchasedDetails);
          // this.purchasedDetails = response;
        })
        .catch((error) => {
          console.log(error);
          if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.getPurchasedDetails);
              this.flagAutologin = false;
              return;
            }
          }
        });
    },
    toggleEditProfilePopup(state) {
      EventBus.$emit("EditProfilePopup", state);
      this.toggleEditPopup = state;
      if (!state) {
        this.getProfileDetails();
      }
    },
    toggleChangePasswordPopup(state) {
      EventBus.$emit("ChangePasswordPopup", state);
      this.toggleChangePassPopup = state;
    },
    toggleDeletesubscriberPopup(state) {
      let localData = JSON.parse(localStorage.getItem("loginType"));

      this.actGetSubscriber(localData.email)
        .then((response) => {
          console.log("MYS", response);
          this.subscriberid = response.subscriberid;
          this.editData = response;
          this.name = response.subscribername;
          this.email = response.email;
          this.renewMode = response?.renewmode;
          this.planStatus = response?.planstatus;
          let appUser = JSON.parse(localStorage.getItem("appUser"));
          this.message =
            this.renewMode == "CALLBACK"
              ? "Payment auto renewal against your subscription is active. Please delete auto renewal from plan page and retry delete account."
              : "You have an IOS in app perchase, please cancel the subscription and retry delete account.";
          console.log("PS", this.planStatus != "CANCEL");
          if (
            this.planStatus == "ACTIVE" &&
            (this.renewMode == "CALLBACK" || "AUTO")
          ) {
            console.log("AAAAAAAAAA");
            this.togglePaymentPopup = state;
            return;
          }

          EventBus.$emit("DeletesubscriberPopup", state);
          this.toggleDeletePopup = state;
        })
        .catch((error) => {
          console.log(error);
          if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.getProfileDetails);
              this.flagAutologin = false;
              return;
            }
          }
        });
    },
  },
  mounted() {
    this.getProfileDetails();
  },
  created() {
    this.actSetCardTab();
    let localAppCon = JSON.parse(localStorage.getItem("appConfig"))
    this.appname = localAppCon.appName
    let loginmode = JSON.parse(localStorage.getItem("loginType"))
    this.issocialLogin = loginmode.type==="GOOGLE" ? true : false
  },
  components: {
    Plans: () =>
      import(/* webpackChunkName: "plans" */ "@/components/Profile/plans.vue"),
    Purchased: () =>
      import(
        /* webpackChunkName: "purchased" */ "@/components/Profile/purchased.vue"
      ),
    Settings: () =>
      import(
        /* webpackChunkName: "settings" */ "@/components/Profile/settings.vue"
      ),
    EditProfilePopup: () =>
      import(
        /* webpackChunkName: "editProfile" */ "@/components/Popups/editProfile.vue"
      ),
    ChangePasswordPopup: () =>
      import(
        /* webpackChunkName:   "changepassword" */ "@/components/Popups/changePassword.vue"
      ),
    DeletePopup: () =>
      import(
        /* webpackChunkName: "popupLogin" */ "@/components/Popups/delete.vue"
      ),
    PaymentcancelPopup: () =>
      import(
        /* webpackChunkName: "popupLogin" */ "@/components/Popups/paymentcancel.vue"
      ),
  },
  mixins: [appMixins],
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";

.vlt-profile-container {
  padding: 3%;
  display: flex;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  color: $font-clr-white-1;
  height: 700px;
  @include breakpoint(max768) {
    padding: 3% 10%;
    height: auto;
    flex-direction: column;
  }
  .profile-details {
    width: 30%;
    // height: 100%;
    background: $clr-bg-gray-light-1;
    border-radius: 5px;
    display: flex;
    flex-flow: column nowrap;
    @include breakpoint(max768) {
      width: 100%;
    }
    .profile-img {
      padding: 10% 31%;
      text-align: center;
      padding-bottom: 3%;

      .profile-img-responsive {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        vertical-align: middle;
        border: solid 1px $theme-primary;
      }
    }

    .profile-details-sub {
      text-align: center;

      .name {
        font-size: 1.25rem;
        color: $font-clr-white;
        font-family: $font-family;
        font-weight: $font-weight-semibold;
        letter-spacing: 0px;
      }
      .email {
        font-family: $font-family;
        font-weight: $font-weight-regular;
        font-size: 1rem;
      }
    }

    .profile-details-sub-1 {
      padding: 7%;
      @include breakpoint(max768) {
        display: flex;
        padding: 7% 25%;
        .gender-dob {
          width: 50%;
        }
      }
      .title {
        margin-top: 5%;
      }
      .details {
        color: $font-clr-white;
        margin-top: 1%;
      }
    }

    .profile-details-sub-2 {
      display: flex;
      padding: 7%;
      @include breakpoint(max768) {
        padding: 3% 25%;
      }
      .edit-change {
        all: unset;
        // width: 48%;
        margin: 0 2%;
        font-size: 1rem;
        font-weight: $font-weight-regular;
        text-align: center;
        background: $clr-color-black-3;
        color: $btn-font-color;
        padding: 0.5rem 0.2rem;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          color: $font-clr-white;
          background: $clr-color-black-4;
        }
        &:active {
          transform: scale(0.9);
        }
      }
      .active-edit-change {
        color: $theme-primary;
        background: $clr-color-black-4;
        transform: scale(1);
      }
    }
    .profile-details-sub-3 {
      display: flex;
      padding: 7%;
      margin-top: auto;
      justify-content: center;
      @include breakpoint(max768) {
        padding: 3% 25%;
      }
      .edit-change {
        all: unset;
        // width: 48%;
        // margin: 0 2%;
        font-size: 1rem;
        font-weight: $font-weight-regular;
        text-align: center;
        color: $btn-font-color;
        padding: 0.5rem 0.2rem;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.2s;
        // margin:0 auto;
        &:hover {
          color: $font-clr-white;
        }
        &:active {
          transform: scale(0.9);
        }
      }
      .active-edit-change {
        color: $theme-primary;
        background: $clr-color-black-4;
        transform: scale(1);
      }
    }
  }

  .profile-setting {
    width: 70%;
    // height: 100%;
    background: $clr-bg-gray-light-1;
    border-radius: 5px;
    margin-left: 2%;
    // overflow-y: auto;
    @include breakpoint(max768) {
      width: 100%;
      margin-left: 0%;
      margin-top: 2%;
    }
    .card-tab {
      right: 0;
      width: 100%;
      border-bottom: solid 2px $clr-bg-gray-light-2;
      // padding: 15px 0px;
      display: flex;
      flex-wrap: wrap;
      @include breakpoint(max768) {
        text-align: center;
      }
      a:first-child {
        margin-left: 4%;
        @include breakpoint(max768) {
          margin-left: 0%;
        }
      }

      .tab-link {
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        text-transform: capitalize;
        font-family: $font-family;
        font-weight: $font-weight-semibold;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.37px;
        line-height: 25px;
        padding: 15px 0;
        padding-right: 3%;
        color: $font-clr-white-1;

        span {
          padding: 5px 24px;
        }
      }
      .activetabname {
        span {
          background: $clr-bg-gray-dark 0% 0% no-repeat padding-box;
          border-radius: 6px;
          opacity: 1;
          color: $font-clr-white;
          border-bottom: 0;
        }
      }
    }

    .tab-details {
      background: $clr-bg-gray-light-1;
      padding: 3%;
      height: 560px;
      overflow-y: auto;
      @include breakpoint(max768) {
        margin-bottom: 1%;
      }
    }
  }
}
</style>