<template>
  <div class="app-wrapper">
    <div :style="[cardStyle, dropdownOpen]" class="app-main-background">
      <div id="app" class="vlt-app">
        <appHeader />
        <message v-if="showMessage" :isOnline="isOnline" />
        <alert-user
          v-if="!checkBrowser()"
          message="This Browser is not supported for video playback.
      Please Use Chrome or Mozilla to watch the Movie."
          :imagesrc="require('@/assets/icons/video_noplay.svg')"
        />
        <alert-user
          v-if="cdmSupport"
          :message="cdmSupport"
          :imagesrc="require('@/assets/icons/video_noplay.svg')"
        />

        <router-view class="vlt-main" id="main" />
        <!-- cookie alert accept deny have to be configured. -->
        <cookie-alert v-if="cookieView" />

        <appFooter v-if="showFooter" />
        <transition name="popup-animate">
          <paymentInitPopup
            v-if="showPaymentInitPopup"
            :paymentData="paymentData"
            :closePopupCB="(data) => togglePaymentInitPopup(data)"
          />
        </transition>
        <FailedPopup
          v-if="toggleFailed"
          :message="errorMessage"
          :page="page"
          :closePopupCB="() => toggleFailedPopup(false)"
        ></FailedPopup>
        <SuccessPopup
          v-if="toggleSuccess"
          :message="errorMessage"
          :page="page"
          :closePopupCB="() => toggleSuccessPopup(false)"
        ></SuccessPopup>
        <FacebookPopup
          v-if="facebookPopup"
          :dataFacebook="dataFacebook"
          :page="facebookredirectPage"
          :closePopupCB="toggleFacebookPopup"
        ></FacebookPopup>
      </div>
    </div>

    <live-player
      v-if="playerContent && (playerContent.contenttype == 'LIVE' || playerContent.contenttype == 'EVENT')"
      :content="playerContent"
      @destroyed="closeplayer()"
    ></live-player>

    <main-player
      v-else-if="playerContent && (playerContent.contenttype != 'LIVE' || playerContent.contenttype != 'EVENT')"
      :content="playerContent"
    ></main-player>
  </div>
</template>

<script>
import { EventBus } from '@/main';
import appMixins from '@/mixins/appMixins';
import GoogleAnalytics from '@/mixins/GoogleAnalytics';
import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/analytics';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CookieAlert from './components/Footer/cookieAlert.vue';
export default {
  name: 'App',
  data() {
    return {
      paymentData: undefined,
      showPaymentInitPopup: false,
      content: null,
      showFooter: true,
      showMessage: false,
      isOnline: undefined,
      toggleFailed: false,
      page: null,
      errorMessage: null,
      toggleSuccess: false,
      flagAutologin1: true,
      flagAutologin2: true,
      showOverlay: false,
      facebookPopup: false,
      dataFacebook: '',
      facebookredirectPage: '',
      detailContent: null,
      dropdownOpen: null,
      dropdownActive: false,
      dropdowns: { menu: false, notification: false },
      cardStyle: { position: 'relative' },
      viewmounted: false,
      playerContent: null,
    };
  },
  watch: {
    isOnline() {},
    playerContent() {
      console.log('PCONT', this.playerContent);
    },
    $route(to, from) {
      let GA = {
        ScreenName: this.$route.name,
      };
      this.ScreenViewEvent(GA);
    },
    subscriberId(newval) {
      if (newval) {
        this.ticketStore();
        this.plansSetValue();
      } else {
        this.commitTicketResponse(null);
        this.commitPlansSubscribedResponse(null);
        EventBus.$emit('purchaseDetailsUpdated');
      }
    },
    cardview(next) {
      if (next) {
        let top = this.cardStyle.top
            ? this.cardStyle.top
            : `${window.scrollY * -1}px`,
          position = 'fixed',
          left = '0';
        this.cardStyle = { position, top, left };
      } else {
        this.cardStyle = { position: 'relative' };
      }
    },
  },
  computed: {
    ...mapGetters([
      'subscriberId',
      'appUser',
      'appConfig',
      'cookiePermission',
      'cdmData',
    ]),
    cardview() {
      return this.$route && this.$route.params.contentid ? true : false;
    },
    cookieView() {
      return this.viewmounted && !this.cookiePermission && !this.subscriberId;
    },
    cdmSupport() {
      if (!this.cdmData) return '';
      if (!this.cdmData.playback)
        return 'This Browser has no Widevine support. Please update.';
      if (!this.cdmData.browserSupport)
        return 'Please use the newer version of the browser for playback.';
      else return '';
    },
    promoCode() {
      return this.$route.path.includes('promo') && this.$route.query?.code;
    },
  },
  components: {
    paymentInitPopup: () =>
      import('@/components/Popups/payment/paymentInit.vue'),
    appHeader: () => import('@/components/Header/appHeader.vue'),
    appFooter: () => import('@/components/Footer/footer.vue'),
    cookieAlert: () => import('@/components/Footer/cookieAlert.vue'),
    message: () => import('@/components/Popups/networkHandling.vue'),
    AlertUser: () => import('@/components/Popups/UserAlerts.vue'),
    MainPlayer: () => import('@/components/Player/mainPlayer.vue'),
    LivePlayer: () => import('@/components/Player/liveplayer.vue'),

    FailedPopup: () =>
      import(
        /* webpackChunkName: "failed popup" */ '@/components/Popups/failed.vue'
      ),
    SuccessPopup: () =>
      import(
        /* webpackChunkName: "success popup" */ '@/components/Popups/success.vue'
      ),
    FacebookPopup: () =>
      import(
        /* webpackChunkName: "facebook popup" */ '@/components/Popups/facebookPopup.vue'
      ),
    //detailCard:()=> import(
    /*webpackChunkName: "detail card"*/
    // "@/components/Shared/templates/detailCard.vue"
    // )
  },
  methods: {
    ...mapActions([
      'actGetTicket',
      'actGetPlans',
      'initFCMNotifications',
      'actGetSubscriber',
    ]),
    ...mapMutations([
      'commitTicketResponse',
      'commitPlansSubscribedResponse',
      'CommitVendorAssets',
      'CommitUserStore',
    ]),
    togglePaymentInitPopup(state) {
      this.showPaymentInitPopup = state;
      if (!state) {
        EventBus.$emit('closedPaymentPopup', true);
      }
    },
    toggleFailedPopup(state) {
      this.toggleFailed = state;
    },
    toggleSuccessPopup(state) {
      this.toggleSuccess = state;
    },
    toggleFacebookPopup(state = false) {
      if (state === 'fbClose') {
        EventBus.$emit('fbPrompt-closed');
        this.facebookPopup = false;
      } else {
        this.facebookPopup = state;
      }
      // if (!state) {
      //   EventBus.$emit("fbPrompt-closed");
      // }
    },
    closeOverlay() {
      this.showOverlay = false;
    },
    ticketStore() {
      if (localStorage.getItem('loginType')) {
        return this.actGetTicket()
          .then((response) => {
            if (!response.error) {
              this.commitTicketResponse(response);
              EventBus.$emit('purchaseDetailsUpdated');
              localStorage.setItem('ticket', JSON.stringify(response));
            }
          })
          .catch((error) => {
            console.log('error ticket ', error);
            // if (error.status === 401) {
            //   this.unauthorisedHandler();
            // }

            if (this.flagAutologin1) {
              this.commonAutoLogin(this.ticketStore);
              this.flagAutologin1 = false;
              return;
            }
          });
      }
    },
    actNetwork() {
      this.showMessage = true;
      this.isOnline = navigator.onLine ? true : false;
      if (this.isOnline === true) {
        setTimeout(() => {
          this.showMessage = false;
        }, 2000);
      }
    },
    plansSetValue() {
      let data = {};
      data.subscriberid = this.subscriberId;
      this.actGetPlans(data)
        .then((response) => {
          // console.log("SUBSCRIBE PLANS RESPONSE====>",response);
          if (!response.error) {
            this.commitPlansSubscribedResponse(response);
            EventBus.$emit('purchaseDetailsUpdated');
          }
        })
        .catch((error) => {
          console.log('error ', error);
          if (error.status === 401) {
            if (this.flagAutologin2) {
              this.commonAutoLogin(this.plansSetValue);
              this.flagAutologin2 = false;
              return;
            }
          }
        });
    },
    updateAppUserDetails() {
      let appUser = this.appUser;
      if (!(appUser && appUser.email)) {
        return false;
      }

      this.actGetSubscriber({ email: appUser.email }).then((response) => {
        let appUser = { ...appUser, ...response };
        appUser.expirydate = appUser.planexpiry;
        let appUserString = JSON.stringify(appUser);
        localStorage.setItem('appUser', appUserString);
        this.CommitUserStore();
      });
    },
    toggleDropdown(state = false) {
      if (state) {
        this.dropdownOpen = { position: 'fixed', overflow: 'hidden' };
      } else {
        this.dropdownOpen = null;
      }
    },
    responsiveDropdown() {
      if (window.screen.width > 480 || !this.dropdownActive) {
        EventBus.$emit('toggle-dropdown-view', false);
      } else if (window.screen.width <= 480 && this.dropdownActive) {
        EventBus.$emit('toggle-dropdown-view', true);
      }
    },
    checkDropdown(val = {}) {
      let dropdownlist = ['notification', 'menu', 'profile'];
      this.dropdowns = { ...this.dropdowns, ...val };
      if (val.menu || val.notification || val.profile) {
        dropdownlist.forEach((name) => {
          if (val[name]) return;
          EventBus.$emit(`close-${name}`, false);
        });
        this.dropdownActive = true;
      } else {
        this.dropdownActive = false;
      }
      this.responsiveDropdown();
    },
    premiereRouting() {
      //promo-route check.
      if (this.promoCode) return;
      //cookie permission required.
      if (!this.cookiePermission && !this.subscriberId) return;
      //app configuration check.
      if (!(this.appConfig && this.appConfig.featureEnabled.posterPromotion))
        return;
      //single promotion in single tab check.
      if (sessionStorage.getItem('poster_is_promoted')) return;

      this.$router.push({ name: 'Promotions' });
      sessionStorage.setItem('poster_is_promoted', 'true');
    },
    togglePlayer(content) {
      if (content?.contentid || content?.trailerid) {
        console.log('TOGGLED', content);
        this.playerContent = { ...content };
        console.log('PLAYER_CCC', this.playerContent);
      } else {
        this.playerContent = null;
      }
    },

    closeplayer() {
      this.playerContent = false;
      console.log('CLOSEDDDD');
    },
  },
  mounted() {
    setTimeout(() => {
      this.viewmounted = true;
    }, 1000);
    if (this.subscriberId) {
      this.initFCMNotifications();
    }
    if (this.$route.name === 'Home' && !this.$route.query.contentid) {
      this.showOverlay = true;
    }
  },
  created() {
    this.premiereRouting();
    let state = window.history && window.history.state;
    if (state && state.detailCard) window.history.back();

    var themeName = JSON.parse(localStorage.appConfig).vendorDetails.vendorId;
    this.updateAppUserDetails();
    // this.setTheme(themeName);
    //notification on refresh
    console.log('subscriber id ', this.subscriberId);
    if (this.subscriberId) {
      this.plansSetValue();
      this.ticketStore();
      // this.initFCMNotifications();
    }

    //facebook mail prompt
    EventBus.$on('facebookLogin', (data) => {
      if (data.facebookToken) {
        this.facebookPopup = data.popupDisplay;
        this.dataFacebook = data.facebookToken;
        this.facebookredirectPage = data.page;
      }
    });

    //newtwork handling
    window.addEventListener('offline', this.actNetwork);
    window.addEventListener('online', this.actNetwork);
    //

    EventBus.$on('loadPlayer', this.togglePlayer);
    EventBus.$on('unloadPlayer', this.togglePlayer);
    EventBus.$on('failure', (data) => {
      this.errorMessage = data.errorMessage;
      this.page = data.page;
      this.toggleFailedPopup(true);
    });
    EventBus.$on('success', (data) => {
      this.errorMessage = data.errorMessage;
      this.page = data.page;
      this.toggleSuccessPopup(true);
    });
    EventBus.$on('paymentInitPopup', (data) => {
      let subscription_popup = JSON.parse(localStorage.appConfig).featureEnabled
        .showPlanAfterLogin;
      if (data.autoAction && !subscription_popup) return;
      this.paymentData = data;
      this.togglePaymentInitPopup(true);
    });
    EventBus.$on('paymentInitPopupClose', (data) => {
      this.togglePaymentInitPopup(data);
    });
    EventBus.$on('planPurchased', (data) => {
      this.ticketStore().then(() => {
        EventBus.$emit('planPurchasedSuccess');
      });
      this.plansSetValue();
      this.updateAppUserDetails();
    });
    EventBus.$on('dropdown-switch', this.checkDropdown);
    EventBus.$on('toggle-dropdown-view', this.toggleDropdown);
    window.addEventListener('resize', this.responsiveDropdown);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.responsiveDropdown);
  },
  mixins: [appMixins, GoogleAnalytics],
};
</script>
<style lang="scss">
@import '~sass/modules/main';
@import '~sass/modules/grid';
@import '~sass/modules/aligners';
@import '~sass/modules/fontnormalizer';
@import '~sass/modules/variables';
//font content loading impacting performance...
// @font-face {
//   font-family: "Montserrat-Bold";
//   src: url("assets/fonts/Montserrat-Bold.otf");
// }
// @font-face {
//   font-family: "Montserrat-Regular";
//   src: url("assets/fonts/Montserrat-Regular.otf");
// }
// @font-face {
//   font-family: "Montserrat-SemiBold";
//   src: url("assets/fonts/Montserrat-SemiBold.otf");
// }
// @font-face {
//   font-family: "Montserrat-Black";
//   src: url("assets/fonts/Montserrat-Black.otf");
// }
// @font-face {
//   font-family: "Montserrat-Medium";
//   src: url("assets/fonts/Montserrat-Medium.otf");
// }
body {
  overflow-x: hidden !important; //setting overflow in main scroll.
}
.app-wrapper {
  width: 100%;
  //overflow-x:hidden;
}
.app-main-background {
  width: 100vw;
}
.detailanimate-enter-active {
  animation: detail-animation 0.3s;
}
.detailanimate-leave-active {
  animation: detail-animation 0.3s reverse;
}

@keyframes detail-animation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

:root {
  --primary-clr: #ff9f0a;
  --btn-color: #ff9f0a;
  --tag-color: #30d158;
}

#cave-india .logo-div {
  bottom: none;
  top: 13%;
  right: 10%;
  width: 8%;
  height: 8%;
}
#cave-india .logo-img {
  width: 30%;
}
.vlt-app {
  min-height: 100vh;
  position: relative;
  padding-bottom: var(--footer-height);
}
.vlt-main {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: calc(100vh - #{$header-total-height} - var(--footer-height));
}
.share-box {
  li:hover .share-link {
    fill: $theme-primary !important;
  }
}

.fill {
  fill: var(--primary-clr);
}
.no-fill {
  fill: rgba(247, 247, 247, 0.6);
}
</style>
