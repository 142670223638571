import Vue from 'vue';
import Vuex from 'vuex';

import { Master } from './modules/Master';
import { User } from './modules/User';
import { Content } from './modules/Content';
import { Consts } from './modules/Consts';
import {Player} from './modules/Player';
// import { Vendor } from './modules/Vendor';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { Master, User, Content, Consts, Player }
});

