import { EventBus } from '@/main';
import { mapGetters, mapActions } from "vuex";
import SimpleCrypto from "simple-crypto-js";
import languages from "../../public/languages.json";
import { getLocalfromUTC, stringToDate } from "@/mixins/dateSupport.js";

const CryptoJS = require("crypto-js");
// var moment = require("moment");
let detailCardRoutes=[
  'ContentList',
  'Home',
  'WatchList',
  'SearchList',  
]

let pgMap={
  'G':'U',
  'PG': 'U/A',
  'R': 'A',
  'NC-17': 'A'
}

const normaliseDateString = ( dateString = '' ) => ( (typeof dateString === 'string' ) ? dateString.replace( /-/g, '/' ) : dateString );
const appMixins = {

  methods: {
    ...mapActions(["actUserAutoLogin"]),
    encode_url(data) {
      data = data.replace(/\+/g, "-");
      data = data.replace(/\//g, "_");
      data = data.replace(/\=/g, ",");
  
      return data;
    },
    getPgRating(val){
      if(this.country!=='IN')return val;
      else return pgMap[val && val.trim()];
    },
    encryptData(plainData, key) {
      var C = CryptoJS;
      var plainText = C.enc.Utf8.parse(plainData);
      key = C.enc.Utf8.parse(key);
      var aes = C.algo.AES.createEncryptor(key, {
        mode: C.mode.ECB,
        padding: C.pad.Pkcs7,
        iv: key,
      });
      var encrypted = aes.finalize(plainText);
      encrypted = C.enc.Base64.stringify(encrypted);
      encrypted = this.encode_url(encrypted);
      return encrypted;
    },

    validateEmail(email) {
      let regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // let regx = /^(?: [a - z0 - 9!#$ %& '*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&' * +/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
      return regx.test(String(email).toLowerCase());
    },
    validateInputName(name) {
      let Tempname = name.startsWith(" ");
      if (Tempname) return false;
      // let regx = /^[a-zA-Z ]+$/;
      let regx = /^[a-zA-Z0-9_ ]*$/;
      return regx.test(name);
    },
    // validateInputPassword(password) {
    //   let regx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,40}$/;
    //   return regx.test(password);
    // },
    isMobile() {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    isTablet() {
      let userAgent = navigator.userAgent.toLowerCase();
      let isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      );
      return isTablet;
    },
    checkBrowser() {
      let device = navigator.userAgent.toLowerCase();
      if (device.indexOf("ubrowser") != -1 || device.indexOf("opr") != -1) {
        return false;
      } else if (
        device.indexOf("chrome") != -1 ||
        device.indexOf("firefox") != -1
        // ||
        // device.indexOf("safari") != -1
      ) {
        return true;
      }
      return false;
    },
    getLanguage(lang = "") {
      // let contentLanguage = "English";
      // if (lang == "EN") {
      //   contentLanguage = "English";
      // }

      // let contentLanguage;
      lang = lang.toLowerCase();

      return languages[lang] && languages[lang].name

      // return contentLanguage;
    },
    getDuration(duration = 0) {
      let hours, minutes, seconds, time = "";
      try {
        minutes = Math.floor(Number(duration) / 60);
        if (minutes > 59) {
          hours = Math.floor(minutes / 60);
          minutes = minutes % 60;
        }
        seconds = duration % 60;

        time = `${hours ? hours + 'h ' : ''}${minutes ? minutes + 'm ' : ''} ${seconds ? seconds + 's' : ''}`
      }

      catch (_) {
        time = "";
      }
      return time;
    },
    collectionsToArray(collections) {
      let arr = [];
      for (var i in collections) {

        let obj = {
          key: i,
          contentid: i,
          title: collections[i].contenttitle,
          ...collections[i],
        };
        arr.push(obj);
      }
      return arr;
    },
    unauthorisedHandler() {
      if (localStorage.getItem("loginType")) {
        let loginType = JSON.parse(localStorage.getItem("loginType"));
        if (loginType.type === "EMAIL") {
          let simpleCrypto = new SimpleCrypto(this.SECRET_KEY);
          let tempPassDec = simpleCrypto.decrypt(loginType.password);
          EventBus.$emit(
            "loginUser",
            {
              email: loginType.email,
              password: tempPassDec,
              app: "SELF"
            },
            "L"
          );
          // setTimeout(() => {
          //   // //window.location.reload()
window.location.assign(window.location.origin);
          //   CB
          // }, 2000);
        }
        else {
          localStorage.removeItem("appUser");
          localStorage.removeItem("loginType");
          // EventBus.$emit("logOut");
          this.$router.push({ name: "Home" });
          // //window.location.reload()
window.location.assign(window.location.origin);
        }
      } else {
        localStorage.removeItem("appUser");
        localStorage.removeItem("loginType");
        // EventBus.$emit("logOut");
        this.$router.push({ name: "Home" });
        // //window.location.reload()
window.location.assign(window.location.origin);
      }

      return;
    },

    checkEventEnded(expiryDateString) {
      if(!expiryDateString)return false;
      let currentDate = new Date();
     
      let expiryDate=getLocalfromUTC(expiryDateString);
  
      return (currentDate > expiryDate);

    },
    checkForContentExpiryDate(expiryDate) {
      //let UTCtoIST = moment.utc(expiryDate).toDate();
      let UTCtoIST = getLocalfromUTC(expiryDate);
      let d1 = this.getTodayDate();
      let d2 = this.getDateFormat(UTCtoIST);
      if (
        // Math.floor(new Date() / 1000) + 60 * 60 >=
        // Math.floor(new Date(expiryDate) / 1000) + 60 * 60
        this.compareDate(d1, d2)
      ) {
        return true;
      } else {
        return false;
      }
    },

    getTodayDate() {
      let today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      let dateTime = date + " " + time;

      return dateTime;
    },
    getDateFormat(data) {
      let date = new Date( normaliseDateString( data ) );
      let IST =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds();

      return IST;
    },
    compareDate(d1, d2) {
      let dateOne = new Date( normaliseDateString( d1 ) );
      let dateTwo = new Date( normaliseDateString( d2 ) );

      if (dateOne > dateTwo) {
        return true;
      } else {
        return false;
      }
    },
    getFeaturedTags(content) {
      let featuredTags = [];
      featuredTags.push(
        content.contenttype,
        content.category,
        this.getLanguage(content.language),
        content.year,

        );

      switch (content.contenttype) {
    
        case "MOVIE":
        case "TVSHOW":
          let duration = this.getDuration(content.duration);
          if(duration?.trim())featuredTags=[...featuredTags.slice(0,-1),duration,...featuredTags.slice(-1)];
          break;
        case "SERIES":
          if (content.episodecount) {
            featuredTags.push(content.episodecount + " Episodes");
          }
          break;
      }
      return featuredTags;
    },
    expiryDetails(date) {
      // let expiryDate = new Date(date);
      // let today = new Date();

      // let UTCtoIST = moment.utc(date).toDate();
      let UTCtoIST = getLocalfromUTC(date);
      let todayTemp = this.getTodayDate();
      let expiryDateTemp = this.getDateFormat(UTCtoIST);

      let today = new Date( normaliseDateString( todayTemp ) );
      let expiryDate = new Date( normaliseDateString( expiryDateTemp ) )

      let diffTime = Math.abs(expiryDate - today);

      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


      if (diffDays > 0) {
        if (diffDays === 1) {
          return "Expires today";
        } else if (diffDays > 1) {
          return "Expiry in " + diffDays + " days";
        }
      } else if (diffDays < 0) {
        return "Expired";
      }
    },
    commonAutoLogin(CB) {
      this.actUserAutoLogin()
        .then((response) => {
          if (response) {
            if (CB) {

              CB();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // let sessionError = {
          //   title: "Session",
          //   message: "Session expired.Please login again.",
          // };
          // EventBus.$emit("CommonErrorPopup", sessionError);
          localStorage.removeItem("appUser");
          localStorage.removeItem("loginType");
          // this.$router.push({ name: "Home" });
          //window.location.reload()
window.location.assign(window.location.origin);

          // setTimeout(() => {
          //   EventBus.$emit("logOut");
          // }, 2000);
        });
    },
    viewCountShort(value) {
      if (value > 999) {
        let suffixes = ["", "K", "M", "B", "T"];
        let suffixNum = Math.floor(( ("" + value).length - 1 ) / 3);
        let shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value));
        if (shortValue % 1 != 0) {
          shortValue = Number( shortValue.toFixed(1) ); //set decimal limit
        }
        return shortValue + suffixes[suffixNum];
      }
      else {
        return value
      }

    },

    sortContinueWatching(responseData) {
     return responseData.sort((cont1, cont2) => {
        return stringToDate(cont1.startdate) <
          stringToDate(cont2.startdate)
          ? 1
          : -1;
      });
    },

   /** Method to open detail card. @params contentid: contentid of the content to be passed. */
    routeToCardView(contentid,replace){
        if(!contentid)return;
        let currentRoute=this.$route.name;
        let rootRoute=currentRoute && currentRoute.split('/')[0];
        if(!detailCardRoutes.includes(rootRoute))return;

        let nextRoute=`${rootRoute}/Card`;
        let method=replace?'replace':'push';
        this.$router[method]({
          name:nextRoute,
          params:{contentid}
        });
    },



  },
  computed: {
    ...mapGetters(["SECRET_KEY","country"])
  },
}

export default appMixins;