<template>
  <div>
    <div class="row">
      <featured-carousel :category="sectionList[0]" />
    </div>
    <div class="container sliders-container"  :style="sliderContainer">
      <!-- <div class="row content-container" v-if="showContinueSlider">

      </div>-->
      <div
        v-for="(sections, i) in sectionList.slice(1)"
        :key="i"
        class="row content-container"
        v-show="isVisible(sections)"
      >
      <!-- <p>{{i}}</p> :class="i ===0? 'content-slider':''" -->
        <div
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xlg-12 content-slider"
          
          v-if="sections.title"
        >
          <component :is="sections.sectionType==='UPCOMING'?'premier-slider':'slider-carousel'"
            :category="sections"
          />
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { EventBus } from "@/main";
import Vue from "vue";

export default {
  name: "Home",
  data() {
    return {
      sectionList: undefined,
      loginstatus: false,
      showContinueSlider: false,
      contentList: undefined,
      sliderContainer: ""
    };
  },
  computed: {
    ...mapGetters([
      "subscriberId",
      "appConfig",
      "appUser",
      "appScreens",
      "country"
    ])
  },
  watch: {
    subscriberId() {
      if (this.subscriberId) {
        this.loginstatus = true;
      }
    }
  },
  methods: {
    // commitContentType
    ...mapMutations(["commitContentList"]),
    ...mapActions(["actListContents"]),
    getSections() {
      if (this.appScreens) {
        let screens = this.appScreens;
        for (let i in screens) {
          if (screens[i].id && screens[i].id === "HOME") {
            this.sectionList = screens[i].sections;
            break;
          }
        }
      }
    },
    isVisible(sections) {
      if (sections.loginRequired) {
        return this.loginstatus;
      }
      return true;
    },
    getContents() {
      let params = {};
      if (
        this.appConfig &&
        this.appConfig.screens &&
        this.appConfig.screens[0].sections
      ) {
        let isCarousal = this.appConfig.screens[0].sections.filter(
          section => section.listType === "CAROUSAL"
        );

        if (
          isCarousal[0].parameters &&
          isCarousal[0].parameters.featured &&
          isCarousal[0].parameters.featured === "YES"
        ) {
          params.featured = isCarousal[0].parameters.featured;
        } else {
          params.featured = "NO";
        }
      }

      this.actListContents(params)
        .then(response => {
          if (response.length > 0) {
            this.contentList = response;
            this.commitContentList(response);
            //EventBus.$emit("featuredCarouselData", this.contentList);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  created() {
    
    EventBus.$on("changeSliderContainer", data => {
      if (data) {
        this.sliderContainer =  "padding-top:0px"
      } else {
        this.sliderContainer =  ""
      }
    });
    if (this.subscriberId) {
      this.loginstatus = true;
    }

    this.getSections();
    this.getContents();

   
  },
  beforeDestroy(){
    
  },
  components: {
    "featured-carousel": () =>
      import(
        /* webpackChunkName: "featuredCarousel" */ "@/components/Carousels/featuredCarousel.vue"
      ),
    "slider-carousel": () =>
      import(
        /* webpackChunkName: "sliderCarousel" */ "./slider/sliderCarousel.vue"
      ),
      "premier-slider":()=>import("./slider/Premiers/premierSlider.vue")
  }
};
</script>

<style lang="scss">
@import "~nm/tiny-slider/src/tiny-slider.scss";
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_mediaQueries.scss";

.container {
  margin-top: 40px !important;
  max-width: var(--max-width);
  padding:0px;
}
.content-container {
  margin-left: 0;
}
.sliders-container.container {
  // padding-top: 50px;
  margin-top:0px !important;
}
.content-slider{
  padding:0px;
}
// .content-slider {
  // margin: 130px 0;
  // margin-top:40px;
// }

@include breakpoint(max768){
  .sliders-container.container {
    margin-right: 0;
    max-width: calc(var(--max-width)/2 + 50%);
  }
  .see-all{
    padding-right: 5vw;
  }
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}
// @media only screen and (max-width: 600px) {
@include breakpoint(max600) {
  .sliders-container {
    padding-top: 0px;
  }
}

// @media only screen and (min-width: 768px) {
@include breakpoint(tablet) {
  .content-container {
    margin-right: 0px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@include breakpoint(min980max1200) {
  .container{
    margin-top: 0px !important;
  }
  .sliders-container{
    padding-top: 0px !important;
  }
}
@include breakpoint(desktopLarge) {
  .sliders-container {
    padding-top: 2%;
  }
}
</style>