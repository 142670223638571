export { appClass } ;
import Vue from 'vue'
import App from '../App'
import router from '../router'
import store from '../store';
import { EventBus } from '@/main';
import {routePageUpdate} from '@/base-configurations/supports';


class appClass {
    constructor () {
      import(/* webpackChunkName: "configClass" */ /* webpackMode: "lazy" */ './config-class').then((config) => {
        this.config = new config.configClass();
      });
      this.routeRegister();
      EventBus.$on('configLoaded', this.loadVue) 
    }
    loadVue () {
      new Vue({
        el: '#app',
        router,
        store,
        components: { App },
        template: '<App/>'
      });
    }
    routeRegister(){
      /** storing internal app routes for reference. */
      router.afterEach((to)=>{
        if( to.meta?.ignore ) return ;
        
        routePageUpdate(to && to.path);
        store.commit('commitRouteHistory',{...to});
      })
    }
}