<template>
  <div class="cookie-wrapper" v-show="notDenied">
      <div class="description">
        <span>
            This site uses cookies for core functionality, personalisation and analytics. 
            You are requested to consent to the use of cookies on this site to have continued 
            access to all of its functionality.
            By clicking on "Accept", you agree to storing of cookies to enhance site navigation,
            analyze site usage, and assist in marketing efforts. For more details, read our  
           <a target="_blank" :href="privacyLink||'#'">Privacy Policy</a>. 
        </span>
      </div>
      <div class="acceptance">
          <button class="btn-deny vlt-btn" @click="updateCookies(false)">Deny</button>
          <button class="btn-accept vlt-btn" @click="updateCookies(true)">Accept</button>
      </div>
  </div> 
</template>

<script>
import {mapGetters,mapMutations} from 'vuex';
export default {
    data(){
        return {
            notDenied:true
        }
    },
    computed:{
        ...mapGetters(['appConfig']),
        privacyLink(){
            return this.appConfig && this.appConfig.vendorDetails.ppUrl;
        }
    },
    methods:{
        ...mapMutations(['commitCookiePermission']),
        updateCookies(val){
            this.notDenied=val;
            this.commitCookiePermission(val);
            localStorage.setItem('cookies_permission',`${val}`);
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~sass/modules/variables";
@import "~sass/modules/fonts";
@import "~sass/modules/components";
@import "~sass/modules/hovereffects";
@import "~sass/modules/mediaQueries";
    .cookie-wrapper{
        position:sticky;
        bottom:0;
        left:0;
        height:max-content;
        width:100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-evenly;
        padding: 40px 10px;
        z-index: 10;
        background: $clr-color-black-2;
        font-family: $font-family;
        color:$font-clr-white-1;
        &>div{
            display:flex;
            flex-flow:row nowrap;
            align-items: center;
            margin:0 10px;
        }
        .description a{
            text-decoration: underline;
            color:$font-clr-white;
        }
        .acceptance{
            display:flex;
            flex-flow:row nowrap;
            align-items: center;
            justify-content: center;
            min-width: 200px;
            .btn-deny{
                padding:8px;
                cursor: pointer;
                background:transparent;
            }
            .btn-accept{
                @include buttonHover;
                padding:8px 16px;
                cursor: pointer;
            }

            @include breakpoint(max600){
                flex-flow:row wrap;
                min-width: 100px;
            }
        }
    }

</style>