import {createTag,createSchema,getEl} from '@/base-configurations/supports.js';
import routes from '@/base-configurations/seo_routes';

  const head=document.querySelector("head");
  const url=window.location.origin;
  const location=window.location.href;

  const routeData=routes.find(({url})=>url.test(location));

  //ONLY FOR LOCALHOSTING...
  const value=routeData?routeData.base:"vlite"; 

  //assign permanent local base.
  Object.defineProperty(window,'base_env',{
    value,
    writable:false,
    configurable:false
  });

  let testing= process.env.VUE_APP_ENV === "development" ;

  if (!testing) {
    if (!window.console) window.console = {};
    window._rootconsole=window.console;
    let methods = ["log", "debug", "warn", "info", "dir"];
    let emptyfn=()=>{};
   window.console=new Proxy(window.console,{
        get(target,prop){
          if(methods.includes(prop)){
            return emptyfn;
          }
          else return Reflect.get(...arguments);
        }
      })
}  


loadMetaTags(value);


async function loadMetaTags(base_env){
    console.log(base_env);
    let {author,title,icon,assetpath,pwaData}=await import(`@/base-configurations/vendors/${base_env}.js`);
    pwaData.path=`${url}/${assetpath}`;

    //SEO meta tags
    let authorMetaTags={};
    authorMetaTags.author=createTag("meta",[['name','author'],['content',author]]);
    authorMetaTags.icon=createTag("link",[
                                    ['rel','icon'],
                                    ['type','image/png'],
                                    ['id','favicon'],
                                    ['sizes',"32x32"],
                                    ['href',icon],
                                  ]);
    head.prepend(...Object.values(authorMetaTags));

    //schema.org creation
    let schema_data={
      name:title,
      url
    } 
    createSchema(schema_data, head);                           
    //schema.org end
    
    //pwa relevant tags
    //if(!pwaData)return; 

    // let pwaTags={};

    // getEl("[rel='manifest']",head)?.setAttribute('href',`${pwaData.path}/manifest.json`);
    // getEl("[rel='apple-touch-icon']",head)?.setAttribute('href',`${pwaData.path}/icons/apple-touch-icon.png`);
    // getEl("[rel='icon'][sizes='32x32']",head)?.setAttribute('href',`${pwaData.path}/icons/favicon-32x32.png`); 
    // getEl("[rel='icon'][sizes='16x16']",head)?.setAttribute('href',`${pwaData.path}/icons/favicon-16x16.png`); 

    // getEl("[name='theme-color']")?.setAttribute('content',pwaData.themeColor);
    // getEl("[name='apple-mobile-web-app-title']")?.setAttribute('content',pwaData.title);

    // getEl("[name='msapplication-TileColor']")?.setAttribute('content',pwaData.msColor);
    // getEl("[name='msapplication-TileImage']")?.setAttribute('content',`${pwaData.path}/icons/mstile-150x150.png`);
    
    // let maskicon=getEl("[rel='mask-icon']");
    // maskicon?.setAttribute('color',pwaData.maskColor);
    // maskicon?.setAttribute('href',`${pwaData.path}/icons/safari-pinned-tab.svg`);
   

    
    // head.append(...Object.values(pwaTags));
                                   
}  

  